<template>
  <Card title="人员考核">
    <div>
      <a-form-model layout="inline" @keyup.enter.native="query" style="margin-bottom: 2vh">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-range-picker style="width: 100%" :placeholder="['起始年月', '结束年月']" format="YYYY-MM-DD"
                v-model="form.time" />
            </a-form-model-item>

            <a-form-model-item>
              <a-select v-model="form.status" placeholder="考核状态" style="width: 150px">
                <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button type="primary" @click="query">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button type="primary" @click="$router.push(`${$route.path}/add`)"
                :disabled="!permissionsAdd">新增</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <a-table bordered @change="onChange" :data-source="list" :loading="loading" rowKey="id" :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }">
        <a-table-column title="计划名称" data-index="name" />

        <a-table-column title="考核起始时间" data-index="timeBegin" align="center"></a-table-column>

        <a-table-column title="考核终止时间" data-index="timeEnd" align="center"></a-table-column>
        <a-table-column title="考核人数" data-index="assessNum" align="center"></a-table-column>
        <a-table-column title="考核状态" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="assessPlanStatus" :dictValue="text.status" />
          </template>
        </a-table-column>

        <a-table-column title="操作" width="140px" align="center">
          <template slot-scope="text">
            <a-space>
              <a-button @click="$router.push({ path: `${$route.path}/edit`, query: text })"
                :disabled="!permissionEdit">编辑</a-button>
              <a @click="$router.push({ path: `${$route.path}/assess`, query: text })">评价</a>
              <a class="danger" @click.prevent="deleteText(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </Card>
</template>
<script>
import { fetchList, del } from "@/api/personnel-assess";
import { mapGetters, } from "vuex";
export default {
  data() {
    return {
      form: {},
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,

    };
  },
  mounted() {
    this.getList();

    console.log("here is 个人考核")
    const hasaddpermission = this.hasPermission("model-analysis:assessPlan:add")
    console.log(hasaddpermission)
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    ...mapGetters("auth", ["hasPermission"]), // 没有参数的getPermission不需要使用函数的形式,有参数的hasPermission就需要以函数形式调用
    typeList() {
      return this.findDataDict("taskStatus");
    },
    permissionAdd() {
      return this.hasPermission("model-analysis:assessPlan:add")
    },
    permissionEdit() {
      return this.hasPermission("model-analysis:assessPlan:edit")
    }
  },
  methods: {
    getList() {
      this.loading = true;
      let { current, pageSize, form } = this;
      let { name, status } = form;

      let timeBegin, timeEnd;
      if (Array.isArray(form.time) && form.time.length === 2) {
        timeBegin = form.time[0].format("YYYY-MM-DD");
        timeEnd = form.time[1].format("YYYY-MM-DD");
      }
      fetchList({
        pageNum: current,
        pageSize,
        status,
        name,
        timeBegin,
        timeEnd,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    query() {
      this.current = 1;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除这条考核计划吗？",
        onOk() {
          del({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>
